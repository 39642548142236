import request from '@/utils/request' 

 
export function getUserRights(data) {
    return request({
        url: '/bd-gms/system/setting/user/rights',
        method: 'get',
        data
    })
}
 
  