import request from '@/utils/request'
 
export function addMaterial(data) {
  return request({
    url: '/bd-gms/device/material',
    method: 'post',
    data
  })
}

export function getMaterialList(data) {
  return request({
    url: '/bd-gms/device/material/list',
    method: 'post',
    data
  })
}

export function deleteMaterial(data) {
    return request({
      url: '/bd-gms/device/material' + "?materialId=" + data.materialId,
      method: 'delete'
    })
  }

  export function updateMaterial(data) {
    return request({
      url: '/bd-gms/device/material/info',
      method: 'post',
      data
    })
  }

  export function getMaterialInfoByCode(data) {
    return request({
      url: '/bd-gms/device/material/info?code=' + data.materialCode,
      method: 'get',
      data
    })
  }
