import { getFlightOverview, getFlightTimeCount, getFlightMileageCount, getFlightDataSizeCount, getFlightTaskCount, getFlightFaultCount, getFlightFaultOverview } from '@/api/flightdata'
import { getToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // user login
  queryflightoverview({ commit }, pageParm) {
    const { companyId, timeBy, timeNo  } = pageParm
    return new Promise((resolve, reject) => {
        getFlightOverview({ companyId: companyId, timeBy: timeBy, timeNo: timeNo }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryflighttimecount({ commit }, pageParm) {
    const { companyId, timeBy, timeNo  } = pageParm
    return new Promise((resolve, reject) => {
        getFlightTimeCount({ companyId: companyId, timeBy: timeBy, timeNo: timeNo }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryflightmileagecount({ commit }, pageParm) {
    const { companyId, timeBy, timeNo  } = pageParm
    return new Promise((resolve, reject) => {
        getFlightMileageCount({ companyId: companyId, timeBy: timeBy, timeNo: timeNo }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryflightdatasizecount({ commit }, pageParm) {
    const { companyId, timeBy, timeNo  } = pageParm
    return new Promise((resolve, reject) => {
        getFlightDataSizeCount({ companyId: companyId, timeBy: timeBy, timeNo: timeNo }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryflighttaskcount({ commit }, pageParam) {
    const { companyId, timeBy, timeNo  } = pageParam
    return new Promise((resolve, reject) => {
        getFlightTaskCount({ companyId: companyId, timeBy: timeBy, timeNo: timeNo }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryflightfaultcount({ commit }, pageParm) {
    const { companyId, timeBy, timeNo  } = pageParm
    return new Promise((resolve, reject) => {
        getFlightFaultCount({ companyId: companyId, timeBy: timeBy, timeNo: timeNo }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
 
  queryflightfaultoverview({ commit }, pageParm) {
    const { companyId, timeBy, timeNo  } = pageParm
    return new Promise((resolve, reject) => {
        getFlightFaultOverview({ companyId: companyId, timeBy: timeBy, timeNo: timeNo }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

