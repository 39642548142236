import request from '@/utils/request'
 
export function getFlightOverview(data) {
  return request({
    url: '/bd-gms/flightdata/material/overview',
    method: 'post',
    data
  })
}

export function getFlightTimeCount(data) {
  return request({
    url: '/bd-gms/flightdata/material/flighttime/count',
    method: 'post',
    data
  })
}

export function getFlightMileageCount(data) {
    return request({
      url: '/bd-gms/flightdata/material/flightmileage/count',
      method: 'post',
      data
    })
  }
  
  export function getFlightDataSizeCount(data) {
    return request({
      url: '/bd-gms/flightdata/material/datasize/count',
      method: 'post',
      data
    })
  }

  export function getFlightTaskCount(data) {
    return request({
      url: '/bd-gms/flightdata/material/taska/count',
      method: 'post',
      data
    })
  }
  
  export function getFlightFaultCount(data) {
    return request({
      url: '/bd-gms/flightdata/material/fault/count',
      method: 'post',
      data
    })
  }

  export function getFlightFaultOverview(data) {
    return request({
      url: '/bd-gms/flightdata/material/fault/overview',
      method: 'post',
      data
    })
  }