import request from '@/utils/request'
 
export function queryPlaneRealtimeInfo(data) {
  return request({
    url: '/bd-gms/record/plane/realtimeinfo',
    method: 'post',
    data
  })
}

export function queryPlaneInfo(data) {
    return request({
        url: "/bd-gms/device/plane/info" + "?planeId=" + data.planeId,
        method: 'get'
    })
}

export function queryPlaneCountInfo(data) {
  return request({
    url: '/bd-gms/record/plane/countinfo',
    method: 'post',
    data
  })
}

export function queryPlaneCas(data) {
    return request({
        url: '/bd-gms/record/plane/cas',
        method: 'post',
        data
    })
  }
  
  export function queryPlaneTasks(data) {
    return request({
      url: '/bd-gms/record/plane/tasks',
      method: 'post',
      data
    })
  }

  export function queryPlaneMaterials(data) {
    return request({
        url: '/bd-gms/device/material/list',
        method: 'post',
        data
    })
  }