const storage = {
    // 设置
    setDataCache(key,value, expired){
       localStorage.setItem(key, JSON.stringify(value), expired)   
     },
    //获得
     getDataCache(key){
        return JSON.parse(localStorage.getItem(key))
    },
    // 移除
    removeDataCache(key){
        localStorage.removeItem(key)
    }
}

export default storage
    