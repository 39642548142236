import request from '@/utils/request'
import axios from 'axios'

export function downloadFlightReport(data, onDownloadProgress) {
    return request({
        url: '/bd-gms/report/flightreport',
        method: 'post',
        responseType: 'blob',
        data,
        onDownloadProgress: onDownloadProgress
    })
}

export function downloadFlightReportLog(data, onDownloadProgress) {
    return request({
        url: '/bd-gms/report/flightlog',
        method: 'post',
        responseType: 'blob',
        data,
        onDownloadProgress: onDownloadProgress
    })
}
