import { queryPlaneRealtimeInfo, queryPlaneInfo,  queryPlaneCountInfo, queryPlaneCas, queryPlaneTasks, queryPlaneMaterials  } from '@/api/planeRecord'
import { getToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  queryplanerealtimeinfo({ commit }, planeInfo) {
    const { planeId } = planeInfo
    return new Promise((resolve, reject) => {
        queryPlaneRealtimeInfo({ planeId: planeId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
 
  queryplanebyid({ commit }, planeInfo) {
    const { planeId } = planeInfo
    return new Promise((resolve, reject) => {
        queryPlaneInfo({ planeId: planeId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryplanecountinfo({ commit }, planeInfo) {
    const { planeId } = planeInfo
    return new Promise((resolve, reject) => {
        queryPlaneCountInfo({ planeId: planeId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },


  queryplanecas({ commit }, planeInfo) {
    const { planeId } = planeInfo
    return new Promise((resolve, reject) => {
        queryPlaneCas({ planeId: planeId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },


  queryplanetasks({ commit }, planeInfo) {
    const { planeId } = planeInfo
    return new Promise((resolve, reject) => {
        queryPlaneTasks({ planeId: planeId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryplanematerials({ commit }, planeInfo) {
    const { pageSize, pageIndex, planeId } = planeInfo
    return new Promise((resolve, reject) => {
        queryPlaneMaterials({ pageSize: pageSize, pageIndex: pageIndex, planeId: planeId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}