import { login, logout, getInfo, getNewInfo } from '@/api/user'
import { getToken, setToken, removeToken, getUserInfo, removetUserInfo, setUserInfo, setRefreshToken, setTokenExpiration } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

// 跟发送请求 401 有关
const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { userCode, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ userCode: userCode, password: password }).then(response => {
        const data = response
        data['userCode'] = userCode
        commit('SET_TOKEN', data.userCode)
        commit('SET_USER_INFO', data)
        setToken(data.token)
        setRefreshToken(data.refresh_token)
        setUserInfo(data)
        setTokenExpiration(data.expiration_date)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  getHelloInfo({ commit }, userInfo) {
    const { id, name } = userInfo
    return new Promise((resolve, reject) => {
      getNewInfo({ id: id, name: name }).then(response => {
        const data = response
      }).catch(error => {
        reject(error)
      })
    })
  },


  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response

        if (!data) {
          return reject('Verification failed, please Login again.')
        }

        const { name, avatar } = data

        commit('SET_NAME', name)
        commit('SET_AVATAR', avatar)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout({ userCode: state.token }).then(() => {
        removeToken() // must remove  token  first
        removetUserInfo()
        resetRouter()
        commit('RESET_STATE')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

