import { getMaterialFaultList, exportMaterialFaults, getMaterialOBDExceptionList, exportMaterialOBDExceptions, queryMaterialTasks,getTestRecords,getMaintanceRecords,getRunnerLogs,exportRunnerLog, 
  exportMaintanceRecord,exportTestRecord, exportMaterialFlightTasks, getMaintanceUsers, genRunnerLogContent } from '@/api/materialrecord'
import { getToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
 
  queryMaterialOBDExceptionlist({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, startTime, endTime, module, level, materialCode, lifecyclePhase  } = pageParm
    return new Promise((resolve, reject) => {
        getMaterialOBDExceptionList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, startTime: startTime, endTime: endTime, 
          level: level, module: module, lifecyclePhase: lifecyclePhase, materialCode: materialCode  }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },


  queryMaterialFaultlist({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, startTime, endTime, module, channelType, type, materialCode, lifecyclePhase  } = pageParm
    return new Promise((resolve, reject) => {
      getMaterialFaultList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, startTime: startTime, endTime: endTime, type: type, 
        channelType: channelType, module: module, lifecyclePhase: lifecyclePhase, materialCode: materialCode  }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  

    querymaterialtasks({ commit }, pageParm) {
    const { pageSize, pageIndex, materialCode, startTime, endTime, searchName, status, lifecyclePhase } = pageParm
    return new Promise((resolve, reject) => {
        queryMaterialTasks({ pageSize: pageSize, pageIndex: pageIndex, materialCode: materialCode, startTime: startTime, endTime: endTime, searchName: searchName, lifecyclePhase: lifecyclePhase, status: status }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  exportMaterialFaultlist({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, startTime, endTime, module, channelType, type, materialCode, lifecyclePhase, obdErrorCodeIds, onDownloadProgress  } = pageParm
    return new Promise((resolve, reject) => {
      exportMaterialFaults({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, startTime: startTime, endTime: endTime, type: type, 
        channelType: channelType, module: module, lifecyclePhase: lifecyclePhase, materialCode: materialCode, obdErrorCodeIds: obdErrorCodeIds }, onDownloadProgress).then(response => {
          const data = response
          resolve(data)
        }).catch(error => {
          reject(error)
        })
    })
  },

  exportMaterialFlightlist({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, startTime, endTime, lifecyclePhase, status, materialCode, recordIds, onDownloadProgress  } = pageParm
    return new Promise((resolve, reject) => {
      exportMaterialFlightTasks({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, startTime: startTime, endTime: endTime, 
        status: status, lifecyclePhase: lifecyclePhase, materialCode: materialCode, ids: recordIds }, onDownloadProgress).then(response => {
          const data = response
          resolve(data)
        }).catch(error => {
          reject(error)
        })
    })
  },


  exportRunnerLogs({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, startTime, endTime, channelType, sn, lifecyclePhase, recordIds, onDownloadProgress  } = pageParm
    return new Promise((resolve, reject) => {
      exportRunnerLog({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, startTime: startTime, endTime: endTime, 
        channelType: channelType, lifecyclePhase: lifecyclePhase, sn: sn, recordIds: recordIds }, onDownloadProgress).then(response => {
          const data = response
          resolve(data)
        }).catch(error => {
          reject(error)
        })
    })
  },

  exportMaintanceRecords({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, startTime, endTime, channelType, sn, lifecyclePhase, recordIds, onDownloadProgress  } = pageParm
    return new Promise((resolve, reject) => {
      exportMaintanceRecord({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, startTime: startTime, endTime: endTime, 
        channelType: channelType, lifecyclePhase: lifecyclePhase, sn: sn, recordIds: recordIds }, onDownloadProgress).then(response => {
          const data = response
          resolve(data)
        }).catch(error => {
          reject(error)
        })
    })
  },

  exportTestRecords({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, startTime, endTime, channelType, sn, lifecyclePhase, recordIds, onDownloadProgress  } = pageParm
    return new Promise((resolve, reject) => {
      exportTestRecord({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, startTime: startTime, endTime: endTime, 
        channelType: channelType, lifecyclePhase: lifecyclePhase, sn: sn, recordIds: recordIds }, onDownloadProgress).then(response => {
          const data = response
          resolve(data)
        }).catch(error => {
          reject(error)
        })
    })
  },

  queryRunnerLogs({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, startTime, endTime, channelType, sn, lifecyclePhase  } = pageParm
    return new Promise((resolve, reject) => {
        getRunnerLogs({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, startTime: startTime, endTime: endTime, 
          channelType: channelType, lifecyclePhase: lifecyclePhase, sn: sn  }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryMaintanceRecords({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, startTime, endTime, channelType, sn, lifecyclePhase, maintUserId } = pageParm
    return new Promise((resolve, reject) => {
        getMaintanceRecords({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, startTime: startTime, endTime: endTime, 
          channelType: channelType, lifecyclePhase: lifecyclePhase, sn: sn, operatorId: maintUserId  }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryMaintanceUsers({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, materialCode  } = pageParm
    return new Promise((resolve, reject) => {
        getMaintanceUsers({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, materialCode: materialCode  }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryTestRecords({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, startTime, endTime, channelType, sn, lifecyclePhase  } = pageParm
    return new Promise((resolve, reject) => {
        getTestRecords({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, startTime: startTime, endTime: endTime, 
          channelType: channelType, sn: sn, lifecyclePhase: lifecyclePhase  }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  genRunnerLogContent({ commit }, param) {
    const { url  } = param
    return new Promise((resolve, reject) => {
      genRunnerLogContent({ url: url }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}