import request from '@/utils/request'
 
export function getStatisticsOverview(data) {
  return request({
    url: '/bd-gms/data/statistics/overview',
    method: 'post',
    data
  })
}


export function getStatisticsOverviewTable(data) {
    return request({
        url: '/bd-gms/data/statistics/overview/table',
        method: 'post',
        data
    })
}

export function getStatisticsFlightTimeCount(data) {
  return request({
    url: '/bd-gms/data/statistics/material/flighttime/count',
    method: 'post',
    data
  })
}
 
  
  export function getStatisticsDataSizeCount(data) {
    return request({
      url: '/bd-gms/data/statistics/material/datasize/count',
      method: 'post',
      data
    })
  }

  export function getStatisticsTaskCount(data) {
    return request({
      url: '/bd-gms/data/statistics/material/task/count',
      method: 'post',
      data
    })
  }
  
  export function getStatisticsFlighttimeTopnTable(data) {
    return request({
      url: '/bd-gms/data/statistics/material/flighttime/topn/table',
      method: 'post',
      data
    })
  }

  export function getStatisticsDatasizeTopnTable(data) {
    return request({
      url: '/bd-gms/data/statistics/material/datasize/topn/table',
      method: 'post',
      data
    })
  }

  export function getStatisticsTaskTopnTable(data) {
    return request({
      url: '/bd-gms/data/statistics/material/task/topn/table',
      method: 'post',
      data
    })
  }


  export function getDeviceStatisticsFlightTimeCount(data) {
    return request({
      url: '/bd-gms/data/statistics/material/flighttime/count/all',
      method: 'post',
      data
    })
  }
   
    
    export function getDeviceStatisticsDataSizeCount(data) {
      return request({
        url: '/bd-gms/data/statistics/material/datasize/count/all',
        method: 'post',
        data
      })
    }
  
    export function getDeviceStatisticsTaskCount(data) {
      return request({
        url: '/bd-gms/data/statistics/material/task/count/all',
        method: 'post',
        data
      })
    }

    export function getDeviceOnPowerCount(data) {
      return request({
        url: '/bd-gms/data/accumulatestatistics/material/onpower/count/all',
        method: 'post',
        data
      })
    }

    export function getDeviceRuntimeCount(data) {
      return request({
        url: '/bd-gms/data/accumulatestatistics/material/runtime/count/all',
        method: 'post',
        data
      })
    }