import request from '@/utils/request'

export function getAuthLicenseList(data) {
  return request({
    url: '/bd-gms/auth/authcode/list',
    method: 'post',
    data
  })
}

export function addAuthLicense(data) {
    return request({
      url: '/bd-gms/auth/authcode',
      method: 'post',
      data
    })
  }

  
export function deleteAuthLicense(data) {
    return request({
        url: '/bd-gms/auth/app' + "?id=" + data.id,
        method: 'delete'
    })
}

export function updateAuthLicense(data) {
    return request({
      url: '/bd-gms/auth/authcode',
      method: 'post',
      data
    })
}

export function updateAuthLicenseStstus(data) {
    return request({
      url: '/bd-gms/auth/authcode/status',
      method: 'post',
      data
    })
}
