import request from '@/utils/request'

export function exportMaterialFaults(data, onDownloadProgress) {
  return request({
    url: '/bd-gms/record/material/fault/export',
    method: 'post',
    responseType: 'blob',
    data,
    onDownloadProgress: onDownloadProgress
  })
}

export function exportMaterialOBDExceptions(data, onDownloadProgress) {
    return request({
      url: '/bd-gms/record/material/obdexception/export',
      method: 'post',
      responseType: 'blob',
      data,
      onDownloadProgress: onDownloadProgress
    })
  }

  export function exportRunnerLog(data, onDownloadProgress) {
    return request({
      url: '/bd-gms/maintance/runlogs/export',
      method: 'post',
      responseType: 'blob',
      data,
      onDownloadProgress: onDownloadProgress
    })
  }
  
  export function exportTestRecord(data, onDownloadProgress) {
    return request({
      url: '/bd-gms/devicetest/record/export',
      method: 'post',
      responseType: 'blob',
      data,
      onDownloadProgress: onDownloadProgress
    })
  }

  export function exportMaintanceRecord(data, onDownloadProgress) {
    return request({
      url: '/bd-gms/maintance/record/export',
      method: 'post',
      responseType: 'blob',
      data,
      onDownloadProgress: onDownloadProgress
    })
  }

  export function getMaterialFaultList(data) {
    return request({
      url: '/bd-gms/record/material/fault/list',
      method: 'post',
      data
    })
  }


export function getMaterialOBDExceptionList(data) {
  return request({
    url: '/bd-gms/record/material/obdexception/list',
    method: 'post',
    data
  })
}

export function exportMaterialFlightTasks(data, onDownloadProgress) {
  return request({
    url: '/bd-gms/record/material/flighttask/export',
    method: 'post',
    responseType: 'blob',
    data,
    onDownloadProgress: onDownloadProgress
  })
}


export function queryMaterialTasks(data) {
  return request({
    url: '/bd-gms/record/material/tasks',
    method: 'post',
    data


  })
}

export function getRunnerLogs(data) {
  return request({
    url: '/bd-gms/maintance/runlogs',
    method: 'post',
    data
  })
}

export function getMaintanceRecords(data) {
  return request({
    url: '/bd-gms/maintance/record/list',
    method: 'post',
    data
  })
}

export function getTestRecords(data) {
  return request({
    url: '/bd-gms/devicetest/record/list',
    method: 'post',
    data
  })
}

export function getMaintanceUsers(data) {
  return request({
    url: '/bd-gms/maintance/record/users?materialCode=' + data.materialCode,
    method: 'get',
    data
  })
}


export function genRunnerLogContent(data) {
  return request({
    url: data.url,
    method: 'get',
    data
  })
}