import { getToken , getUserInfo } from '@/utils/auth'
import { addFirmware, getFirmwareList, deleteFirmware, updateFirmware, downloadFirmware, deleteFirmwares, downloadFirmwares, getFirmwareTypeList,deleteFirmwareType,addFirmwareType } from '@/api/firmware'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
      }
}
 
const actions = {
  createfirmware({ commit }, firmwareInfo) {
    const { id, companyId, type, fileName, forceUpgrade, releaseNote } = firmwareInfo
    return new Promise((resolve, reject) => {
        addFirmware({ id: id, targetCompany: companyId, type: type, fileName: fileName, forceUpgrade: forceUpgrade, releaseNote: releaseNote }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryfirmwarelist({ commit }, pageParam) {
    const { pageSize, pageIndex, searchValue, companyId, type } = pageParam
    return new Promise((resolve, reject) => {
        getFirmwareList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchValue, companyId:companyId, type:type }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  downloadfirmwarebyid({ commit }, firmwareInfo) {
    const { firmwareId, onDownloadProgress } = firmwareInfo
    return new Promise((resolve, reject) => {
        downloadFirmware({ id: firmwareId }, onDownloadProgress).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  downloadfirmwares({ commit }, firmwareInfo) {
    const { firmwareIds, onDownloadProgress  } = firmwareInfo
    return new Promise((resolve, reject) => {
        downloadFirmwares(firmwareIds, onDownloadProgress ).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  deletefirmwarebyid({ commit }, firmwareInfo) {
    const { firmwareId } = firmwareInfo
    return new Promise((resolve, reject) => {
        deleteFirmware({ id: firmwareId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  deletefirmwares({ commit }, firmwareInfo) {
    const { firmwareIds } = firmwareInfo
    return new Promise((resolve, reject) => {
        deleteFirmwares(firmwareIds).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryfirmwaretypelist({ commit }, pageParam) {
    const { pageSize, pageIndex, searchValue } = pageParam
    return new Promise((resolve, reject) => {
        getFirmwareTypeList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchValue }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  createfirmwaretype({ commit }, firmwaretypeInfo) {
    const { menuTypes } = firmwaretypeInfo
    return new Promise((resolve, reject) => {
        addFirmwareType(menuTypes).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
 
  deletefirmwaretype({ commit }, firmwareInfo) {
    const { firmwareTypeId } = firmwareInfo
    return new Promise((resolve, reject) => {
        deleteFirmwareType({ id: firmwareTypeId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

