import request from '@/utils/request'

export function getFaultChargePersonList(data) {
  return request({
    url: '/bd-gms/faultcode/chargeperson/list',
    method: 'post',
    data
  })
}

export function addFaultChargePerson(data) {
    return request({
        url: '/bd-gms/faultcode/chargeperson',
        method: 'post',
        data
    })
}

export function updateFaultChargePerson(data) {
    return request({
        url: '/bd-gms/faultcode/chargeperson/info',
        method: 'post',
        data
    })
}