<template>
    <div class="logo">
       <img v-bind:src="logoicon"  @click="toggleCollapse" />
    </div>
        <!--  :default-active="activeIndex  确定主页的菜单   -->
    <el-menu
      active-text-color="#ffd04b"
      background-color="#fff"
      class="el-menu-vertical-demo"
      :default-active="activeIndex"
      text-color="#000000"
      :collapse="isCollapse"
      :collapse-transition="false"
      popper-effect="light"
      router="true"
      @open="handleOpen"
      @close="handleClose">
        <el-menu-item 
            v-for="(menu, index) in menus" 
            :key="index"
            :index="menu.index"
            @mouseenter="handleMouseEnter(index)"
            @mouseleave="handleMouseLeave(index)"
            @click="handleClick(index)">
            <el-icon>
                <img 
                class="menu-icon" 
                :class="{'active-icon': menu.isActive, 'hover-icon': menu.isHover}" 
                :src="menu.icon" 
                alt="icon" />
            </el-icon>
            <template #title>
                <div class="menu-title">{{ menu.title }}</div>
            </template>
        </el-menu-item>
      </el-menu>
  </template>
   
   <script>
   import { useRoute } from 'vue-router';
   import { getUserInfo } from '@/utils/auth';
   import { 
     HelpFilled, Menu as IconMenu, Unlock, Operation, Monitor, Histogram, User, OfficeBuilding, Files, Setting 
   } from "@element-plus/icons-vue";
   
   export default {
     data() {
       return {
         isCollapse: false,  // 控制菜单是否折叠
         logoicon: require('@/assets/boundaryai_small.png'),  // Logo图标
         isActive: false,  // 控制激活状态
         isHover: false,  // 控制鼠标悬停状态
         menus: [{
             index: "/flightmonitor",
             icon: require('@/icons/menu/monitor_s_w.png'),   //  默认生效菜单项
             icon_normal: require('@/icons/menu/monitor_s_b.png'),
             icon_hover: require('@/icons/menu/monitor_s_cyan.png'),
             icon_active: require('@/icons/menu/monitor_s_w.png'),
             title: "设备实时分布",
             isActive: false,
             isHover: false
           }, {
             index: "/material",
             icon: require('@/icons/menu/device_s_b.png'),
             icon_normal: require('@/icons/menu/device_s_b.png'),
             icon_hover: require('@/icons/menu/device_s_cyan.png'),
             icon_active: require('@/icons/menu/device_s_w.png'),
             title: "设备管理",
             isActive: false,
             isHover: false
           }, {
             index: "/devicedata",
             icon: require('@/icons/menu/datastatics_s_b.png'),
             icon_normal: require('@/icons/menu/datastatics_s_b.png'),
             icon_hover: require('@/icons/menu/datastatics_s_cyan.png'),
             icon_active: require('@/icons/menu/datastatics_s_w.png'),
             title: "统计数据分析",
             isActive: false,
             isHover: false
           }, {
             index: "/faultdata",
             icon: require('@/icons/menu/faultstatics_s_b.png'),
             icon_normal: require('@/icons/menu/faultstatics_s_b.png'),
             icon_hover: require('@/icons/menu/faultstatics_s_cyan.png'),
             icon_active: require('@/icons/menu/faultstatics_s_w.png'),
             title: "故障统计分析",
             isActive: false,
             isHover: false
           }, {
             index: "/account",
             icon: require('@/icons/menu/user_s_b.png'),
             icon_normal: require('@/icons/menu/user_s_b.png'),
             icon_hover: require('@/icons/menu/user_s_cyan.png'),
             icon_active: require('@/icons/menu/user_s_w.png'),
             title: "账号管理",
             isActive: false,
             isHover: false
           }, {
             index: "/company",
             icon: require('@/icons/menu/company_s_b.png'),
             icon_normal: require('@/icons/menu/company_s_b.png'),
             icon_hover: require('@/icons/menu/company_s_cyan.png'),
             icon_active: require('@/icons/menu/company_s_w.png'),
             title: "公司管理",
             isActive: false,
             isHover: false
           }, {
             index: "/authcode",
             icon: require('@/icons/menu/authcode_s_b.png'),
             icon_normal: require('@/icons/menu/authcode_s_b.png'),
             icon_hover: require('@/icons/menu/authcode_s_cyan.png'),
             icon_active: require('@/icons/menu/authcode_s_w.png'),
             title: "授权管理",
             isActive: false,
             isHover: false
           }, {
             index: "/faultcode",
             icon: require('@/icons/menu/faultcode_s_b.png'),
             icon_normal: require('@/icons/menu/faultcode_s_b.png'),
             icon_hover: require('@/icons/menu/faultcode_s_cyan.png'),
             icon_active: require('@/icons/menu/faultcode_s_w.png'),
             title: "故障码管理",
             isActive: false,
             isHover: false
           }, {
             index: "/firmware",
             icon: require('@/icons/menu/firmware_s_b.png'),
             icon_normal: require('@/icons/menu/firmware_s_b.png'),
             icon_hover: require('@/icons/menu/firmware_s_cyan.png'),
             icon_active: require('@/icons/menu/firmware_s_w.png'),
             title: "固件管理",
             isActive: false,
             isHover: false
           }
         ],
         menMap: {
           "/flightreport": "/material",
           "/materialrecord": "/material",
           "/authorizedapp": "/authcode",
         },
         activeIndex: "/flightmonitor",  // 默认激活的菜单项
       };
     },
     methods: {
        handleOpen(key, keyPath) {
            // console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            // console.log(key, keyPath);
        },
        toggleCollapse() {
            // isCollapse.value = !isCollapse.value; // No need to collapse
            // logoicon = this.isCollapse ? require("@/assets/logo-small.png") : require("@/assets/boundaryai_small.png");
        },
        handleMouseEnter(index) {
            this.menus[index].isHover = true;  // 直接赋值更新 isHover
            this.menus.forEach((menu, i) => {
                menu.icon = menu.icon_normal
                if(menu.index == this.activeIndex) {
                    menu.icon = menu.icon_active
                } 
            });
            this.menus[index].icon = this.menus[index].icon_hover
            if( this.menus[index].index == this.activeIndex) {
                this.menus[index].icon = this.menus[index].icon_active
            }  
        },
        handleMouseLeave(index) {
            this.menus[index].isHover = false; // 直接赋值更新 isHover
            this.menus.forEach((menu, i) => {
                menu.icon = menu.icon_normal
                if(menu.index == this.activeIndex) {
                    menu.icon = menu.icon_active
                }
            });
        },
        handleClick(index) {
            this.activeIndex = this.menus[index].index
            console.log("activeIndex: " + this.activeIndex)
            this.menus.forEach((menu, i) => {
                menu.isActive = (i === index); // 直接赋值更新 isActive
                menu.icon = menu.icon_normal
                if(i === index) {
                    menu.icon =  menu.icon_active
                }
            });
        }
     },
     watch: {
       // 监听路由的变化，更新 activeIndex
       '$route.path': function(newPath) {
         this.activeIndex = this.menMap[newPath] == null ? newPath : this.menMap[newPath];
       }
     }
   };
   </script>
   
  <style> 
  .el-sub-menu__title {
    height: 30px !important;
    line-height: 30px !important;
    margin-left: 25px;
  }

  .el-menu-item[data-v-795bc4d9]:hover {
    color: #00accd;
    background: #00accd00 !important;
}
  </style>
  <style scoped>
  .logo {
    border-bottom: 1px solid #E8ECEF;
    align-items: center;
    margin: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }
  .my-label {
    background: #ffffff !important;
  }
  .my-content {
    background: var(--el-color-danger-light-9);
  }

  .el-popper.is-dark {
    color: var(--el-bg-color);
    background: #e95e0e !important; 
    border: 1px solid #14f235;
}
 
.el-menu {
    border-right: solid 0px #ee7904 !important;   /* 菜单效果 */
}

.el-menu-item [class^=el-icon] {
    margin-right: 20px;
    margin-left: 5px;
}

.el-sub-menu .el-icon {
    margin-right: 20px;
}

.el-sub-menu .el-menu {
    margin-left: 25px;
}

.el-sub-menu .el-menu-item {
    margin: 0px;
    margin-left: 45px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.el-sub-menu .el-menu-item {
    width: 155px;
}

.menu-title {
    width: 56px;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: Medium;
    text-align: left;
    line-height: 20px;
}


.el-menu-item {
    color: #324257;
    font-weight: 600;
    height: 36px;
    width: 160px;
    margin: 20px;
    padding-top: 18px;
    padding-bottom: 18px;
}



.el-menu-vertical-demo {
    .el-menu-item {
        &.is-active {
            color: #fffffe;
            background: #00accd !important;
            border-radius: 2px;
            box-shadow: 0px 3px 6px 0px rgba(132, 199, 228, 0.74);
        }

        &:hover {
            color: #00accd;
            background: #00accd00 !important;
        }

        &.is-active:hover {
            color: #fffffe; /* 保持 active 状态的文字颜色 */
            background: #00accd !important; /* 保持 active 状态的背景颜色 */
            /* 如果你想要加一些效果，比如深色的阴影，可以在这里加 */
            /* box-shadow: 0px 3px 6px 0px rgba(132, 199, 228, 0.74); */
        }
    }
}


.el-menu-item {
  padding: 10px;
}

.menu-icon {
  width: 16px;
  height: 16px;
  transition: all 0.3s ease;
}

.active-icon {
  /* 可以在这里定义选中状态时图标的特殊样式 */
 /* border: 2px solid blue;  /* 示例: 给选中图标添加边框 */
}

.hover-icon {
  /* 可以在这里定义 hover 状态时图标的特殊样式 */
  opacity: 0.8;
}

.el-menu-item.is-active .menu-icon {
  width: 16px;
  height: 16px;
}
</style>