import Cookies from 'js-cookie'

const TokenKey = 'vue_admin_template_token'
const Refresh_Token = 'vue_admin_refresh_token'
const Token_Expirate = 'vue_admin_token_expirate'


export function getToken() {
  return Cookies.get(TokenKey)
}

export function getRefreshToken() {
  return Cookies.get(Refresh_Token)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function setRefreshToken(token) {
  return Cookies.set(Refresh_Token, token)
}

export function setUserInfo (data) {
  return localStorage.setItem('userInfo', JSON.stringify(data))
}

export function getUserInfo () {
  return localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
}

export function removetUserInfo () {
  return localStorage.removeItem('userInfo')
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function removeRefreshToken() {
  return Cookies.remove(Refresh_Token)
}

export function getCookie (key) {
  return Cookies.get(key)
}

export function setCookie (key, value) {
  return Cookies.set(key, value)
}

export function isTokenExpireate() {
  let timestamp = new Date().getTime();
  let expireate = Cookies.get(Token_Expirate);
  // 输出时间戳
  let isTokenTimeOut = (expireate - timestamp) < 1000
  if (isTokenTimeOut) {
    console.log("TokenTimeOut timestamp = "  + timestamp + ", TokenTimeOut expireate=" + expireate);
  }
   return isTokenTimeOut
}

export function setTokenExpiration(value) {
  return Cookies.set(Token_Expirate, value)
}