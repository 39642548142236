import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/login/login.vue'
import HomeView from '../views/home.vue'
import motreialrecordview from '../views/device/MaterialRecord.vue'
import FlightReportView from '../views/device/FlightReport.vue'

import { getToken, setToken, setRefreshToken } from '@/utils/auth'

/**
 * path、name、component必须唯一，否则空白页
 */
const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    redirect: 'flightmonitor'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    /* meta: { title: "首页", breadcrumb: {
      path:"/home", title: "首页"
    }},  */  // 首页面包屑暂时不要了
    children: [
      {
        path: '',
        redirect: 'flightmonitor'
      },
      {
        path: '/company',
        name: 'CompanyView',
        component: () => import('../views/user/CompanyView.vue'),
        meta: { title: "公司管理", breadcrumb: {
          path:"/company", title: "公司管理"
        }}
      },
      {
        path: '/companyproject',
        name: 'companyprojectview',
        component: () => import('../views/user/CompanyProject.vue'),
        meta: { title: "公司管理", breadcrumb: [{
            path:"/company", title: "公司管理"
          },{
            path:"/companyproject", title: "项目管理" }
        ]},
        children: []
      },
      {
        path: '/plane',
        name: 'PlaneTableView',
        component: () => import('../views/device/PlaneTable.vue'),
        meta: { title: "飞机管理", breadcrumb: {
          path:"/plane", title: "飞机管理"
        }}
      },
      {
        path: '/planerecord',
        name: 'PlaneRecordView',
        component: () => import('../views/device/PlaneRecord.vue'),
        meta: { title: "飞机档案", breadcrumb: {
          path:"/planerecord", title: "飞机档案"
        }}
      },
      {
        path: '/taskdetail',
        name: 'TaskDetailView',
        component: () => import('../views/device/TaskDetail.vue'),
        meta: { title: "任务详情", breadcrumb: {
          path:"/taskdetail", title: "任务详情"
        }}
      },
      {
        path: '/material',
        name: 'MaterialTableView',
        component: () => import('../views/device/MaterialTable.vue'),
        meta: { title: "设备管理", breadcrumb: {
          path:"/material", title: "设备管理"
        }}
      },
      {
        path: '/materialrecord',
        name: 'materialrecordview',
        component: motreialrecordview,
        meta: { title: "设备档案", breadcrumb: [{
            path:"/material", title: "设备管理"
          },{
            path:"/materialrecord", title: "设备档案" }
        ]},
        children: []
      },
      {
        path: '/flightreport',
        name: 'flightreportview',
        component: FlightReportView,
        meta: { 
          title: "飞行报告", 
          breadcrumb: [{
            path:"/material", title: "设备管理" },{
            path:"/materialrecord", title: "设备档案" },{
            path:"/flightreport", title: "飞行报告"}
        ]}
      },
      {
        path: '/account',
        name: 'UserAccount',
        component: () => import('../views/user/UserManager.vue'),
        meta: { title: "账号管理", breadcrumb: {
          path:"/account", title: "账号管理"
        }}
      },
      {
        path: '/role',
        name: 'RoleView',
        component: () => import('../views/user/RoleView.vue'),
        meta: { title: "角色管理", breadcrumb: {
          path:"/role", title: "角色管理"
        }}
      },
      {
        path: '/personcenter',
        name: 'PersonCenter',
        component:() => import('../views/user/PersonCenter.vue'),
        meta: { title: "个人中心", breadcrumb: {
          path:"/personcenter", title: "个人中心"
        }}
      },
      {
        path: '/flightdata',
        name: 'FlightDataView',
        component: () => import('../views/data/FlightData.vue'),
        meta: { title: "飞行数据", breadcrumb: {
          path:"/flightdata", title: "飞行数据"
        }}
      },
      {
        path: '/faultdata',
        name: 'faultdataView',
        component: () => import('../views/data/FaultData.vue'),
        meta: { title: "故障数据分析", breadcrumb: {
          path:"/faultdata", title: "故障数据分析"
        }}
      },
      {
        path: '/devicedata',
        name: 'devicedataView',
        component: () => import('../views/data/DeviceData.vue'),
        meta: { title: "统计数据分析", breadcrumb: {
          path:"/devicedata", title: "统计数据分析"
        }}
      },
      {
        path: '/flightmonitor',
        name: 'MonitorView',
        component: () => import('../views/monitor/FlightMonitor.vue'),
        meta: { title: "设备实时分布", breadcrumb: {
          path:"/flightmonitor", title: "设备实时分布"
        }}
      },
      {
        path: '/faultcode',
        name: 'FaultCodeView',
        component: () => import('../views/fault/FaultCodeSetting.vue'),
        meta: { title: "故障码配置", breadcrumb: {
          path:"/faultcode", title: "故障码配置"
        }}
      },
      {
        path: '/chargeperson',
        name: 'ChargePersonView',
        component: () => import('../views/fault/ChargePerson.vue'),
        meta: { title: "故障码管理", breadcrumb: {
          path:"/chargeperson", title: "故障码管理"
        }}
      },
      {
        path: '/avionicsfirmware',
        name: 'AvionicsFirmware',
        component: () => import('../views/firmware/AvionicsFirmware.vue'),
        meta: { title: "飞控固件", breadcrumb: {
          path:"/avionicsfirmware", title: "飞控固件"
        }}
      },
      {
        path: "/authcode",
        name: "authcode",
        component: () => import('../views/authcode/AuthorizedCode.vue'),
        meta: {
          title: '授权管理', breadcrumb: {
            path:"/authcode", title: "授权管理"
          }
        },
        children: [
          {
            path: '/authcode/app',
            name: 'authapp',
            component: () => import('../views/authcode/AuthorizedApp.vue'),
            meta: { title: "授权软件", breadcrumb: {
              path:"/authcode/app", title: "授权软件"
            }}
          }
        ]
      },
      {
        path: '/authorizedapp',
        name: 'authorizedapp',
        component: () => import('../views/authcode/AuthorizedApp.vue'),
        meta: { 
          title: "授权软件", 
          breadcrumb: [{
            path:"/authcode", title: "授权管理" },{
            path:"/authorizedapp", title: "授权软件"}
        ]}
      },
      {
        path: "/firmware",
        name: "firmware",
        component: () => import('../views/firmware/Firmware.vue'),
        meta: {
          title: '固件管理', breadcrumb: {
            path:"/firmware", title: "固件管理"
          }
        }/*
        children: [
          {
            path: '/firmware/gcsfirmware',
            name: 'GcsFirmware',
            component: () => import('../views/firmware/GcsFirmware.vue'),
            meta: { title: "地面站固件", breadcrumb: {
              path:"/firmware/gcsfirmware", title: "地面站固件"
            }}
          },
          {
            path: '/firmware/toolsfirmware',
            name: 'ToolsCenterFirmware',
            component: () => import('../views/firmware/ToolsCenterFirmware.vue'),
            meta: { title: "工具中心固件", breadcrumb: {
              path:"/firmware/toolsfirmware", title: "工具中心固件"
            }}
          }
        ]*/
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
 /*const breadcrumb = [];
  // 获取上级路由的面包屑
  if (to.meta.breadcrumb) {
    breadcrumb.push(to.meta.breadcrumb);
  }
  // 更新当前路由的面包屑
  breadcrumb.push({ title: to.meta.title, path: to.path, params: to.params });
  
  // 将面包屑存储在路由元信息中
  to.meta.breadcrumb = breadcrumb;  */
  if (to.path == '/firmware' && (from.path == '/firmware/gcsfirmware' || from.path == '/firmware/toolsfirmware')) {
    // 父子菜单的情况，父菜单又没有实际意义，禁用路由
    // return;
  }

  if (to.path ==  from.path) {
    // 适应导航面包屑，对于当前页面点击，导致IE地址栏变化，因此禁用路由
    return;
  }


  const toPath = to.path;
  const fromPath = from.path;
  console.log(fromPath)
  console.log(toPath)

  // 跳转到登录页面就清空数据
  if (toPath.includes('/login')) {
    setRefreshToken('')
    setToken('')
  }
 
  // 没有登录，跳转到登录页面
  if ((getToken() == null || getToken() == '') && (!toPath.includes('/login'))) {
    next('/login')
    // next()
  } else {
    next()
  }  
});

router.onError((err) => {
  console.log(err)
})


export default router