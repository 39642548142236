import { getFaultChargePersonList, addFaultChargePerson, updateFaultChargePerson } from '@/api/faultChargePerson'
import { getToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
    createFaultChargePerson({ commit }, faultPerson) {
      const { name, modules, email } = faultPerson
      return new Promise((resolve, reject) => {
          addFaultChargePerson({ personName: name, modules: modules, email: email}).then(response => {
          const data = response
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },

  // user login
  queryFaultChargePersonList({ commit }, pageParam) {
    const { pageSize, pageIndex, searchName } = pageParam
    return new Promise((resolve, reject) => {
        getFaultChargePersonList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
 
  modFaultChargePerson({ commit }, param) {
    const { id,  email, modules, name } = param
    return new Promise((resolve, reject) => {
        updateFaultChargePerson({ id: id, email: email, modules: modules, personName: name, }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

