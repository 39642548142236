import request from '@/utils/request'
 
export function getOnlineMaterials(data) {
  return request({
    url: '/bd-gms/online/material/list?code=' + data.code,
    method: 'get'
  })
}

export function getOnlineMaterialTasks(data) {
  return request({
    url: '/bd-gms/online/material/tasklist?code=' + data.code,
    method: 'get'
  })
}



export function getMaterialRealTimeInfo(data) {
  return request({
    url: '/bd-gms/online/material/realtimeinfo?code=' + data.materialId,
    method: 'get'
  })
}

export function getMaterialRealTimeTask(data) {
    return request({
      url: '/bd-gms/online/task/realtimeinfo?code=' + data.materialId + '&taskCode=' + data.taskId,
      method: 'get'
    })
  }



