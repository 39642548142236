import { getMaterialList, addMaterial, deleteMaterial, updateMaterial, getMaterialInfoByCode } from '@/api/material'
import { getToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // user login
  creatematerial({ commit }, materialInfo) {
    const { type, materialCode } = materialInfo
    return new Promise((resolve, reject) => {
        addMaterial({ materialCode: materialCode, type: type }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  querymateriallist({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, companyId, type, status, projectId,lifecyclePhase, sourceFrom  } = pageParm
    return new Promise((resolve, reject) => {
        getMaterialList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, companyId: companyId, type:type, status: status, projectId: projectId, lifecyclePhase:lifecyclePhase, sourceFrom:sourceFrom }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  deletematerialbyid({ commit }, materialInfo) {
    const { materialId } = materialInfo
    return new Promise((resolve, reject) => {
        deleteMaterial({ materialId: materialId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 只允许修改可修改的字段
  updatematerial({ commit }, materialInfo) {
    const { materialId, companyId, planeId, project,lifecyclePhase, type } = materialInfo
    return new Promise((resolve, reject) => {
        updateMaterial({ id: materialId, companyId: companyId, planeId: planeId, projectId: project, lifecyclePhase:lifecyclePhase, type: type }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 只允许修改可修改的字段
  queryMaterialInfoByCode({ commit }, materialInfo ) {
    const { materialCode } = materialInfo
    return new Promise((resolve, reject) => {
      getMaterialInfoByCode({ materialCode: materialCode }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

