<template>
  <svg :class="svgClass" aria-hidden="true">
      <use :xlink:href="iconName" />
  </svg>
</template>

<script>
  export default {
      name: "SvgIcon",
      props: {
          iconClass: {
              type: String,
              required: true,
          },
          className: {
              type: String,
              default: '',
          },
      },
      computed: {
          iconName() {
              return `#icon-${this.iconClass}`
          },
          svgClass() {
              if (this.className) {
                  return 'svg-icon ' + this.className
              } else {
                  return 'svg-icon'
              }
          },
      }
  }
</script>

<style scoped>
.svg-icon {
width: 1em;
height: 1em;
fill: currentColor;
overflow: hidden;
vertical-align: middle;
}
</style>