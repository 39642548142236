import { getAccountList, addAccount, deleteAccount, resetPassword, updateAccount,getAccount, updateUserName, updateUserPassword } from '@/api/UserAccount'
import { getToken, getUserInfo } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    userInfo: getUserInfo(),
    name: '',
    avatar: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // user login
  createaccount({ commit }, userInfo) {
    const { name, company, email, role, userCode } = userInfo
    return new Promise((resolve, reject) => {
        addAccount({ name: name, company: company, email: email, role: role, userCode: userCode  }).then(response => {
        const data = response
         
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getaccount({ commit }, userInfo) {
    const { id } = userInfo
    return new Promise((resolve, reject) => {
        getAccount({  userId: id  }).then(response => {
        const data = response
         
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  queryuseraccountlist({ commit }, pageParm) {
    const { pageSize, pageIndex, searchName, companyId, roleId  } = pageParm
    return new Promise((resolve, reject) => {
        getAccountList({ pageSize: pageSize, pageIndex: pageIndex, searchName: searchName, companyId: companyId, roleId: roleId  }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  deleteuseraccountbyid({ commit }, useraccountInfo) {
    const { useraccountId } = useraccountInfo
    return new Promise((resolve, reject) => {
        deleteAccount({ accountId: useraccountId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  resetpasswordbyid({ commit }, useraccountInfo) {
    const { useraccountId } = useraccountInfo
    return new Promise((resolve, reject) => {
        resetPassword({ accountId: useraccountId }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  updateuseraccount({ commit }, useraccountInfo) {
    const { useraccountId, company, email, role, userCode, name} = useraccountInfo
    return new Promise((resolve, reject) => {
        updateAccount({ id: useraccountId, name: name, company: company, email: email, role: role, userCode: userCode }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  updateusername({ commit }, useraccountInfo) {
    const { useraccountId, name} = useraccountInfo
    return new Promise((resolve, reject) => {
        updateUserName({ id: useraccountId, name: name }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  updateuserpassword({ commit }, useraccountInfo) {
    const { useraccountId, password, newPassword, confirmPassword} = useraccountInfo
    return new Promise((resolve, reject) => {
      updateUserPassword({ id: useraccountId, password: password, newPassword: newPassword, confirmPassword: confirmPassword }).then(response => {
        const data = response
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }
  

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

