import { createApp } from 'vue'
import App from '../App.vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './modules/app'
import settings from './modules/settings'
import user from './modules/user'
import company from './modules/company'
import useraccount from './modules/useraccount'
import role from './modules/role'
import material from './modules/material'
import plane from './modules/plane'
import materialrecord from './modules/materialRecord'
import planerecord from './modules/planeRecord'
import flightdata from './modules/flightData'
import firmware from './modules/firmware'
import flightTask from './modules/flightTask'
import flightMonitor from './modules/flightMonitor'
import faultCode from './modules/faultCode'
import faultChargePerson from './modules/faultChargePerson'
import systemSetting from  './modules/systemSetting'
import authApp from  './modules/authApp'
import authLicense from  './modules/authLicense'
import report from  './modules/report'
import maintuser from './modules/maintUser'
import project from './modules/project'

import devicedata from './modules/devicedata'
import faultdata from './modules/faultdata'

createApp(App).use(Vuex)

const store = new Vuex.Store({
    modules: {
      app,
      settings,
      user,
      company,
      useraccount,
      role,
      material,
      plane,
      materialrecord,
      planerecord,
      flightdata,
      flightTask,
      flightMonitor,
      firmware,
      faultChargePerson,
      faultCode,
      systemSetting,
      authApp,
      authLicense,
      report,
      maintuser,
      project,
      devicedata,
      faultdata
    },
    getters
  })
  
  export default store
