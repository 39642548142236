<template>
  <div id="app" class="app-containt">
    <router-view class="router-containt"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
</style>
