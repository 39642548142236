import request from '@/utils/request'
 
export function getFaultstatisticsOverview(data) {
  return request({
    url: '/bd-gms/data/faultstatistics/overview',
    method: 'post',
    data
  })
}
 
 
  
  export function getFaultstatisticsOverviewTable(data, pageData) {
    return request({
      url: '/bd-gms/device/fault/table?pageSize=' + pageData.pageSize + "&pageIndex=" + pageData.pageIndex,
      method: 'post',
      data
    })
  }

  export function getFaultstatisticsFirmwareversionTopn(data) {
    return request({
      url: '/bd-gms/data/faultstatistics/firmwareversion/topn',
      method: 'post',
      data
    })
  }
  
  export function getFaultStatisticsFaultmoduleTopn(data) {
    return request({
      url: '/bd-gms/data/faultstatistics/faultmodule/topn',
      method: 'post',
      data
    })
  }

  export function getFaultStatisticsFaultcodeTopn(data) {
    return request({
      url: '/bd-gms/data/faultstatistics/faultcode/topn',
      method: 'post',
      data
    })
  }

  export function getFaultStatisticsCount(data) {
    return request({
      url: '/bd-gms/data/faultstatistics/count',
      method: 'post',
      data
    })
  }

  export function getDeviceFaultOverviewStatistics(data) {
    return request({
      url: '/bd-gms/data/faultstatistics/material/overviewstatistics/all',
      method: 'post',
      data
    })
  }

